import * as React from "react"
import { Text } from '@fidize/ui.text';
import { Flex } from '@fidize/ui.flex';
import { Vspace } from '@fidize/ui.vspace';
import { Button } from '@fidize/ui.button';
import { Link } from 'gatsby'
import { MdReportGmailerrorred } from 'react-icons/md';

const NotFoundPage = () => {
  return (
    <>
      <head>
        <title>Fidize - Pagina non trovata</title>
      </head>
      <Flex type="column">
        <Vspace desk="60px" mobile="30px" />
        <Flex type="justify_center">
          <Text type="h1 darkgrey" style={{fontSize: '130px'}}><MdReportGmailerrorred /></Text>
        </Flex>
        <Flex type="justify_center">
          <Text type="h1 black">404</Text>
        </Flex>
        <Flex type="justify_center">
          <Text type="h1 black">Not found</Text>
        </Flex>
        <Vspace desk="30px" mobile="20px" />
        <Flex type="justify_center">
          <Link to="/">
            <Button onClick={(btn) => {window.addToCart(btn)}} style={{padding: "10px 30px"}}>
              <Text type="h4 bold white">Torna alla home</Text>
            </Button>
          </Link>
        </Flex>
        <Vspace desk="60px" mobile="30px" />
      </Flex>
    </>
  )
}

export default NotFoundPage
